import { Component, OnInit, Input, OnChanges, SimpleChanges,ViewChild } from '@angular/core';
import { ConfigService } from '../../config-builder/service/config.service';

declare var $:any;
@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.css']
})
export class ViewReportComponent implements OnInit {
	@Input() report_id = -1;
	@Input() hidden_filter_object?: any = {};
	@Input() visible_filter_object?: any = {};
	query_id = -1;
	loading =false;
	report_data_list = []
	cols= []
    attributes_added = false;
    current_index = -1;
	query_con=-1;
	show_refresh = true
  show_view_filter = false
	param_def = {
		'hidden_param_val':[],
		'param_val':[],
		'max_records':100
	}
	report_attributes = [{
      "name": "Percentage",
      "check": false
    },{
      "name": "Minimum",
      "check": false
    },{
      "name": "Maximum",
      "check": false
    },{
      "name": "Mean and S.D.",
      "check": false
    // },{
    //   "name": "Standard Deviation",
    //   "check": false
    }];
    qry_string = ''

	data_view_config: any = {};

	constructor(
	    public _configService: ConfigService,

	) { }


	ngOnInit() {
		if(this.report_id != -1){
			this.loading = true
			this.get_report_data();
		}
	}
	ngOnChanges(changes:SimpleChanges) {
		if(this.report_id != -1 && changes['visible_filter_object'] != undefined && changes['visible_filter_object']['previousValue'] != undefined){
			this.loading = true
			this.override_filters();
		}
	}
	async get_report_data(){
		let res1 = await this._configService.get_report_by_id({'id':this.report_id}).toPromise()
		this.cols = res1.data.col_def
		this.show_refresh = (res1.data.report_style_config['show_refresh']!= null && res1.data.report_style_config['show_refresh']!= undefined) ?res1.data.report_style_config['show_refresh']:true

		if(res1.data.param_def != null){
			this.param_def = res1.data.param_def;
		}
		let filter_object = {...this.hidden_filter_object, ...this.visible_filter_object};

		let temp_payload = {
			'hidden_filter_data':this.param_def.hidden_param_val,
			'filter_data':this.param_def.param_val,
			"hidden_filter_object": filter_object
		}
		this.query_id = res1.data.query_id
		this.data_view_config = res1.data.data_view_config? res1.data.data_view_config:null;
    if(this.data_view_config){
      this.show_view_filter = (this.data_view_config['show_view_filter']!= null && this.data_view_config['show_view_filter']!= undefined) ? this.data_view_config['show_view_filter']:false
    }
		let res4 = await this._configService.process_query_filters(temp_payload).toPromise()
		if(res4.errCode == 0){
			this.param_def.hidden_param_val = res4.msg.hidden_filter_data;
			this.param_def.param_val = res4.msg.filter_data;
			this.override_filters();

		}
   	}
   	async override_filters(){
   		let data = this._configService.process_and_override_filter(this.param_def.hidden_param_val,this.param_def.param_val,this.hidden_filter_object,this.visible_filter_object)
		this.param_def.hidden_param_val = data["hidden_filter_data"]
		this.param_def.param_val = data["filter_data"]
		if(!this.param_def.max_records){
			this.param_def.max_records = 100
		}

		let res2 = await this._configService.get_query_by_id({'id':this.query_id}).toPromise()
      	{
	      	this.qry_string = res2.data.qry_string;
			this.query_con = res2.data.query2connection;
			this.refresh_report_data();

      	}
   	}
  view_filter_data = []
	async refresh_report_data(){
		this.loading = true;
		this.report_data_list = [];
    this.view_filter_data = JSON.parse(JSON.stringify(this.param_def.param_val))
		let payload = {
      		"count": this.param_def.max_records,
      		"hidden_param_list": this.param_def.hidden_param_val,
            "paramList": this.param_def.param_val,
      		"qid": this.query_id,
			"conid":this.query_con,
      	};
      	let res3 = await this._configService.test_query_by_id(payload).toPromise()

		let temp_obj = {};
		for(var rec=0; rec<res3.data.length; rec++){
			temp_obj = {};
			for(var i=0; i<res3.msg.length; i++){
				for(var j=0; j< this.cols.length; j++){
		  			if(res3.msg[i]['field_name'].toLowerCase() == this.cols[j]['col_name'].toLowerCase()){
		  				temp_obj[this.cols[j]['col_name']] = res3.data[rec][i];
		  			}
				}
			}
			this.report_data_list.push(temp_obj)
		}
		this.loading = false

	}
 //	download_attribute_report(){

 //      var payload = { "data_records": this.report_data_list, "data_header": this.report_header };
 //      this._configService.download_chart_report(payload).subscribe(res => {

 //        var payload2 = { "file_type": "report", "file_name": res.filename };
 //        this._configService.download_report(payload2).subscribe(res2 => {
 //          window.open(res2.download_link, "_blank");
 //        });

 //      });
 //    }

	download_btn_text = 'Download Report'
	download_report(){
		///////////// ATTRIBUTES CODE STARTS ////////////
	    // if(this.attributes_added == true){
	    //   this.download_attribute_report()
	    //   return false;
	    // } else {
		///////////// ATTRIBUTES CODE ENDS ////////////
		this.download_btn_text = 'Downloading'
		var payload = {
			"report_id": this.report_id,
			"filter_data": this.param_def.param_val,
			"hidden_filter_data": this.param_def.hidden_param_val
		};
		this._configService.create_report(payload).subscribe(res => {
			var payload2 = {"file_type": "report", "file_name": res.filename};
			if (((localStorage.getItem('leggero_username') != null) || (localStorage.getItem('leggero_username') != undefined))) {
				const is_admin=JSON.parse(localStorage.getItem('lg_admin'));
				payload2["logdata"] = {
					"logtype": "report_export",
					"logdata": {
						"user_name": localStorage.getItem('leggero_username'),
						"main_itemname": res.filename,
						"main_itemid": this.report_id,
						"dashboard_name": localStorage.getItem('lg_dashboard_name'),
						"system_access_id": localStorage.getItem('system_access_id'),
						"is_admin_panel": is_admin == undefined? 0: is_admin					}
				}
			}

				this._configService.download_report(payload2).subscribe(res2 => {
					this.download_btn_text = 'Download Report'
					window.open(res2.download_link, "_blank");
				});

		},error => {
    		this.download_btn_text = "Download Report"
		});
    // }

	}
	config_event(event){
		if(event.case == "apply_filter"){
			this.refresh_report_data();
		}
	}
///////////// ATTRIBUTES CODE STARTS ////////////
	// reset_report(){
        // this.attributes_added = false;
        // for(var i=0; i<this.report_attributes.length; i++){
        //   this.report_attributes[i]['check'] = false;
        // }
	// }
	// add_attributes(){
 //      // console.log(this.payload.col_def);
 //      // console.log(this.report_header);
 //      // console.log(this.report_data_list);
 //      // console.log(this.report_attributes);
 //      this.attributes_added = true;

 //      for(var i=0; i<this.report_attributes.length; i++){
 //        if(this.report_attributes[i]['check']){
 //          // console.log(this.report_attributes[i]['name']);
 //          this.apply_attribute_by_type(this.report_attributes[i]['name']);
 //        }
 //      }

 //    }

 //    add_attributes_v2(){
 //      this.attributes_added = true;
 //      if(this.report_attributes[this.current_index]['check'] == false){
 //        this.report_attributes[this.current_index]['check'] = true;
 //        this.apply_attribute_by_type(this.report_attributes[this.current_index]['name']);
 //      } else {
 //        // this.snotifyService.error('Attribute already applied.', this.utilityService.getConfig());
 //      }

 //      // for(var i=0; i<this.report_attributes.length; i++){
 //      //   if(this.report_attributes[i]['check']){
 //      //     // console.log(this.report_attributes[i]['name']);
 //      //     this.apply_attribute_by_type(this.report_attributes[i]['name']);
 //      //   }
 //      // }

 //    }

 //    apply_attribute_by_type(type){
 //      switch(type){
 //        case 'Percentage':
 //          this.apply_percent_rule();
 //        break;
 //        case 'Minimum':
 //          //  add rules
 //          this.apply_minimum_rule();
 //        break;
 //        case 'Maximum':
 //          this.apply_maximum_rule();
 //        break;
 //        case 'Mean and S.D.':
 //          this.apply_mean_rule();
 //        break;
 //        // case 'Standard Deviation':
 //        //   this.apply_std_deviation_rule();
 //        // break;
 //        default:
 //        break;
 //      }
 //    }

 //    apply_minimum_rule(){
 //      // Add Minimum Header
 //      this.cols.push({
 //        "col_name": "minimum",
 //        "disp_name":"Minimum"
 //      });

 //      // check if all values are int, else NA
 //      var original_cols = JSON.parse(JSON.stringify(this.cols));

 //      var flag = true;
 //      for(var i=0; i<this.report_data_list.length; i++){
 //        var min = undefined;
 //        flag = true;
 //        for(var k in this.report_data_list[i]){
 //          for(var j=0; j<original_cols.length; j++){
 //            if(k == original_cols[j]['col_name']){
 //              if(isNaN(this.report_data_list[i][k])){
 //                this.report_data_list[i]['minimum'] = 'NA';
 //                flag = false;
 //                break;
 //              } else if (parseFloat(this.report_data_list[i][k]) < min || min == undefined){
 //                min = parseFloat(this.report_data_list[i][k]);
 //              }
 //            }
 //          }
 //          if(flag){
 //            this.report_data_list[i]['minimum'] = min;
 //          } else {
 //            break;
 //          }
 //        }
 //      }
 //      console.log(this.report_data_list);

 //    }

 //    apply_maximum_rule(){
 //      // Add Minimum Header
 //      this.cols.push({
 //        "col_name": "maximum",
 //        "disp_name":"Maximum"
 //      });

 //      // check if all values are int, else NA
 //      var original_cols = JSON.parse(JSON.stringify(this.cols));

 //      var flag = true;
 //      for(var i=0; i<this.report_data_list.length; i++){
 //        var max = undefined;
 //        flag = true;
 //        for(var k in this.report_data_list[i]){
 //          for(var j=0; j<original_cols.length; j++){
 //            if(k == original_cols[j]['col_name']){
 //              if(isNaN(this.report_data_list[i][k])){
 //                this.report_data_list[i]['maximum'] = 'NA';
 //                flag = false;
 //                break;
 //              } else if (parseFloat(this.report_data_list[i][k]) > max || max == undefined){
 //                max = parseFloat(this.report_data_list[i][k]);
 //              }
 //            }
 //          }
 //          if(flag){
 //            this.report_data_list[i]['maximum'] = max;
 //          } else {
 //            break;
 //          }
 //        }
 //      }
 //      console.log(this.report_data_list);

 //    }

 //    apply_percent_rule(){
 //      // Header added at the end
 //      var i,j;
 //      var original_cols = JSON.parse(JSON.stringify(this.cols));
 //      for(i=0; i<original_cols.length; i++){
 //        this.cols.push({
 //          "col_name": original_cols[i]['col_name']+"_percent",
 //          "disp_name": original_cols[i]['disp_name']+" Percentage"
 //        });
 //      }
 //      // console.log(this.report_header);

 //      var max_limit = this.report_data_list.length;
 //      // Data appended at the end with similar col names
 //      var temp = {};
 //      for(i=0; i<original_cols.length; i++){
 //        temp[original_cols[i]['col_name']] = {};
 //        for(j=0; j<this.report_data_list.length; j++){
 //          for(var key in this.report_data_list[j]){
 //            if(original_cols[i]['col_name'] != key){
 //              continue;
 //            }
 //            // if(temp[original_cols[i]['col_name']][key] == undefined){
 //            //   temp[original_cols[i]['col_name']][key] = {};
 //            // }
 //            if(temp[original_cols[i]['col_name']][this.report_data_list[j][key]] == undefined){
 //              temp[original_cols[i]['col_name']][this.report_data_list[j][key]] = 1;
 //            } else {
 //              temp[original_cols[i]['col_name']][this.report_data_list[j][key]] += 1;
 //            }
 //          }
 //        }
 //      }
 //      // console.log(temp);

 //      // add keys to record object
 //      for(j=0; j<this.report_data_list.length; j++){
 //        for(var k in temp){
 //          this.report_data_list[j][k+'_percent'] = (temp[k][this.report_data_list[j][k]]*100/max_limit).toFixed(2)+"%";
 //        }
 //      }

 //    }

 //    apply_mean_rule(){

 //      var i,j,k,l,k1;
 //      var original_cols = JSON.parse(JSON.stringify(this.cols));

 //      var max_limit = this.report_data_list.length;
 //      var valid_cols = [];

 //      var temp = {};
 //      for(i=0; i<original_cols.length; i++){
 //        temp[original_cols[i]['col_name']] = {"sum": 0, "count": 0, "value_list": []};
 //        for(j=0; j<this.report_data_list.length; j++){
 //          for(var key in this.report_data_list[j]){
 //            if(original_cols[i]['col_name'] == key){ //  && $.inArray(key, invalid_cols) === -1
 //              if(!isNaN(this.report_data_list[j][key])){
 //                temp[key]['sum'] += parseFloat(this.report_data_list[j][key]);
 //                temp[key]['count'] += 1;
 //                temp[key]['value_list'][j] = parseFloat(this.report_data_list[j][key]);
 //              } else {
 //                temp[key]['value_list'][j] = 'NA';
 //              }

 //            }

 //          }
 //        }
 //      }

 //      var variance;
 //      for(i=0; i<original_cols.length; i++){
 //        k = original_cols[i]['col_name'];
 //        k1 = original_cols[i]['disp_name'];
 //        temp[k]['mean'] = (temp[k]['sum']/temp[k]['count']).toFixed(2);
 //        if(!isNaN(temp[k]['mean'])){
 //          console.log(k);

 //          variance = 0;
 //          for(l=0; l<temp[k]['value_list'].length; l++){
 //            if(temp[k]['value_list'][l] != 'NA'){
 //              variance += Math.pow((temp[k]['value_list'][l] - temp[k]['mean']), 2);
 //            }
 //          }
 //          temp[k]['SD'] = (Math.sqrt(variance/temp[k]['count'])).toFixed(2);

 //          this.cols.push({
 //            "col_name": k+"_mean",
 //            "disp_name": k1+" Mean"
 //          });
 //          this.cols.push({
 //            "col_name": k+"_sd",
 //            "disp_name": k1+" Standard Deviation"
 //          });

 //          for(l=0; l<temp[k]['value_list'].length; l++){
 //            if(temp[k]['value_list'][l] != 'NA'){
 //              this.report_data_list[l][k+"_mean"] = temp[k]['mean'];
 //              this.report_data_list[l][k+"_sd"] = temp[k]['SD'];
 //            } else {
 //              this.report_data_list[l][k+"_mean"] = 'NA';
 //              this.report_data_list[l][k+"_sd"] = 'NA';
 //            }
 //          }

 //        }

 //      }
 //      // console.log(temp);


 //    }
//////////// ATTRIBUTES CODE ENDS /////////////


}

