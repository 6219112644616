import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
// import { InitService } from './init.service';
import { EnvService } from './env.service';
import { Observable, throwError, of } from 'rxjs';
import { tap, catchError, finalize, map } from "rxjs/operators";
import { Router } from '@angular/router';

import { SnotifyService } from 'ng-snotify';
import { UtilityService } from '../services/utility.service';
import { SharedService } from './shared.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptService implements HttpInterceptor {

    service_count = 0;

    constructor(
        // private _init: InitService, 
        private _env: EnvService,
        private sharedService: SharedService,
        private snotifyService: SnotifyService,
        private utilityService: UtilityService,
        private _route: Router,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var temp = req.url.split('/');
        var service = "/" + temp.slice(3).join("/");
        const querySelectorIdx = service.indexOf('?') // for reqs with queryParams, extract the route till '?'
        if(querySelectorIdx != -1) {
            service  = service.slice(0,querySelectorIdx);
        }

		if (this._env.cache_service.includes(service)) {
			let body = this.sharedService.get_cached_data(service, req.body)
			if (body.status) {
				return of(new HttpResponse(
					{ status:200, body: body['data'] }
				));
			}
		}

		if (!(this._env.skip_spinner_for_services.includes(service) || 
			service.indexOf('/api/lg_dshb_group_user') != -1) ) {
			// console.log(service);
			this.service_count++; // increament count for every http-req service-calls intercepted
			this.sharedService.spinnerVisibility(true);
			//   console.log('total service count', this.service_count);
		}
		var token;
		for (var i = 0; i < this._env.services_to_skip.length; i++) {
			if (this._env.services_to_skip.includes(service)) {	//	skip token addition to service
				token = null;
			} else {
				token = localStorage.getItem('token');
			}
		}

		if (service.substring(0, 4) == '/api') {
			let authReq = req.clone({
				body: req.body,
			});
			if (token) { //  && req.method != 'GET'
				authReq = req.clone({
					headers: req.headers.append('Authorization', 'Bearer ' + token.replace(/"/g, '')),
					body: req.body,
				});
			}
			return next.handle(authReq).pipe(
				// map((event: HttpEvent<any>) => {
				// 	   if (event instanceof HttpResponse) {
				// 		   return event.clone({ body : this.modifyBody(event.body)});
				// 	   }
				//    }),
				finalize(() => {
					if (!(this._env.skip_spinner_for_services.includes(service) || service.indexOf('/api/lg_dshb_group_user') != -1)) {
						// console.log(service)
						this.service_count--; // decreament when service is completed (success/failed both handled)
						if (this.service_count === 0) {
							this.sharedService.spinnerVisibility(false);
							// console.log('present service count', this.service_count);
						}
					}
				}),
				catchError(err => {
					if (err instanceof HttpErrorResponse) {
						console.log('Processing http error', err);
						this.snotifyService.error(err.error.message, this.utilityService.getConfig());
					}
					return throwError(err);
				})
			)
		} else {
			let authReq = req.clone({
				body: req.body,
			});
			if (token ) { // && service.substring(0, 6) != '/whapi'
				authReq = req.clone({
					headers: req.headers.append('Authorization', 'Bearer ' + token.replace(/"/g, '')),
					body: req.body,
				});
			}
			if (authReq.body !== null && authReq.body.version_id == undefined && this.sharedService.dds_ver_id !== null) {
				authReq.body.version_id = this.sharedService.dds_ver_id
			}
			return next.handle(authReq).pipe(
				tap((event: HttpEvent<any>) => {
					if (event instanceof HttpResponse && event.body.errCode != undefined) {
						if (event.body.errCode != 0) {
							const err_literal = {
								403: () => {
									if (this.service_count === 1) {
										// event body contains msg.
										this.snotifyService.error(event.body.msg, this.utilityService.getConfig());;
									}
									setTimeout(() => {
										// debounce added for err-msg display.
										location.reload();
									}, 500);
								},
								98: () => {
									if (this.service_count === 1) {
										this.snotifyService.error(event.body.msg, this.utilityService.getConfig());;
									}
									localStorage.removeItem('token');
									this._route.navigate(['/admin_lg/login']);
								},
                                96: ()=> {
                                    if (this.service_count === 1) {
                                        this.snotifyService.error(event.body.msg, this.utilityService.getConfig());;
                                    }
                                    localStorage.removeItem('token');
                                    this._route.navigate(['/admin_lg/login']);
                                }
							}
							if (err_literal[event.body.errCode]) {
								return err_literal[event.body.errCode](this);
							}
							if (event.body.msg) {
								this.snotifyService.error(event.body.msg, this.utilityService.getConfig());
							} else {
								this.snotifyService.error("Some Error had occured", this.utilityService.getConfig());
							}
						} else if (this._env.cache_service.includes(service)) {
							this.sharedService.set_cached_data(service, authReq.body, event.body)
						} 
					} 
				}), finalize(() => {
					if (!this._env.skip_spinner_for_services.includes(service)) {
						// console.log(service);
						this.service_count--; // decreament when service is completed (success/failed both handled)
						if (this.service_count === 0) {
							this.sharedService.spinnerVisibility(false);
							// console.log('present service count', this.service_count);
						}
					}
				}),
			);
		}

        // modifyBody(response) {
        //  var res = response;
        //  if (res.objects) {
        //      res.errCode = 0;
        //  } else {
        //      res.errCode = 1;
        //  }
        //  return res;
        // }
    }

}
