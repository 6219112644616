import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigService } from '../../config-builder/service/config.service';

declare var $: any
@Component({
	selector: 'app-table-modal',
	templateUrl: './table-modal.component.html',
	styleUrls: ['./table-modal.component.css']
})
export class TableModalComponent implements OnInit, OnDestroy {
	@Input() id = '';
	@Input() data_view_type;
	@Input() data_view_id = null;
	@Input() row_detail = [];
	@Input() show_lg_data_download?: boolean= true;
	@Input() col_detail = [];
	@Input() button_template_select = false;
	@Input() selected_templates = [];
	@Input() selected_data_objects = {};
	@Input() drill_list = [];
	@Output() modal_event: EventEmitter<any> = new EventEmitter<any>();
	@Output() ext_btn_event: EventEmitter<any> = new EventEmitter<any>();
	@Input() external_buttons?: any = []
	selected_row = -1


	// [
	//     {
	//         "button_label": "TASK",
	//         "button_title": "Task",
	//         "button_icon": "tasks",
	//         "button_case": "task_id",
	//         "button_type": "label",
	//         "key_for_lg_id": "task_id"
	//     },
	//     {
	//         "button_label": "Task Status",
	//         "button_type": "label",
	//         "button_title": "Task Status",
	//         "button_icon": "angry",
	//         "button_case": "task_status",
	//         "key_for_lg_id": "task workflow status"
	//     }
	// ]
	selected_temp_index = -1;
	constructor(
		private _configService: ConfigService,
	) { }

	ngOnInit() {
	}
	close_modal() {
		this.selected_row = -1
		$('#' + this.id).modal('hide');
		// this.drill_list.splice(-1, 1);

	}

	sendMail() {
		this.modal_event.emit({ 'case': 'send_mail', 'value': this.selected_temp_index });
	}

	download_chart_report() {

		var header = [];
		for (var i = 0; i < this.col_detail.length; i++) {
			header.push({ "col_name": this.col_detail[i], "disp_name": this.col_detail[i] });
		}
		var payload = { "data_records": this.row_detail, "data_header": header };

		this._configService.download_chart_report(payload).subscribe(res => {
			let payload2 = { "file_type": "report", "file_name": res.filename };
			if (((localStorage.getItem('leggero_username') != null) || (localStorage.getItem('leggero_username') != undefined))) {
				const is_admin=JSON.parse(localStorage.getItem('lg_admin'))
				payload2['logdata'] = {
				"logtype": this.data_view_type,
				"logdata": {
					"user_name": localStorage.getItem('leggero_username'),
					"main_itemname": res.filename,
					"main_itemid": this.data_view_id,
					"drills": this.drill_list,
					"dashboard_name": localStorage.getItem('lg_dashboard_name'),
					"system_access_id": localStorage.getItem('system_access_id'),
					"is_admin_panel": is_admin == undefined? 0: is_admin,
				}}
			}
			this._configService.download_report(payload2).subscribe(res2 => {
				console.log(payload2)
				window.open(res2.download_link, "_blank");
			});
		});

	}

	ext_btn_event_handler(btn) {
		let data = {
			"button_case": btn['button_case'],
			"lg_data_rec": this.row_detail[this.selected_row],
			"lg_id": this.row_detail[this.selected_row][btn['key_for_lg_id']] ? this.row_detail[this.selected_row][btn['key_for_lg_id']] : null,
		}
		console.log(data)
		this.ext_btn_event.emit(data);
		console.log('emitted')
	}

	ngOnDestroy() {
		this.close_modal();
	}

	select_row(n) {
		this.selected_row = n
		console.log(this.row_detail[n])
	}


}
