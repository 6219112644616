import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Ng2CompleterModule } from 'ng2-completer';

import { Ng2LoadingSpinnerModule } from 'ng2-loading-spinner';
import { ANIMATION_TYPES } from 'ng2-loading-spinner';


import { FusionChartsModule } from 'angular-fusioncharts';
import * as FusionCharts from '../../../assets/js/fusioncharts/fusioncharts';
import * as Charts from '../../../assets/js/fusioncharts/fusioncharts.charts';
import * as PowerCharts from '../../../assets/js/fusioncharts/fusioncharts.powercharts';
import * as ExcelExport from '../../../assets/js/fusioncharts/fusioncharts.excelexport';
import * as ZoomLine  from '../../../assets/js/fusioncharts/fusioncharts.zoomline.js';
import * as Gantt  from '../../../assets/js/fusioncharts/fusioncharts.gantt';
import * as Widgets  from '../../../assets/js/fusioncharts/fusioncharts.widgets';
import * as OverLappedColumn2D  from '../../../assets/js/fusioncharts/fusioncharts.overlappedcolumn2d.js';
import * as OverLappedBar2D  from '../../../assets/js/fusioncharts/fusioncharts.overlappedbar2d.js';

FusionCharts.options.SVGDefinitionURL = 'absolute';
FusionChartsModule.fcRoot(FusionCharts, Charts, PowerCharts, ExcelExport,Gantt, Widgets, OverLappedColumn2D, OverLappedBar2D, ZoomLine);


import { ConfigBuilderModule } from '../config-builder/config-builder.module'
import { CompositeWidgetModule } from '../composite-widget/composite-widget.module'
import { ReportModule } from '../report/report.module'
import { ViewDashboardComponent } from './view-dashboard/view-dashboard.component'
import { ViDrillFilterComponent } from './vi-drill-filter/vi-drill-filter.component'
import { DashboardRoutingModule } from './dashboard-routing.module';
import { QuillModule } from 'ngx-quill';
import { DashboardTabsComponent } from './dashboard-tabs/dashboard-tabs.component';
import { ConfigService } from '../config-builder/service/config.service';

@NgModule({
  declarations: [ViewDashboardComponent,ViDrillFilterComponent, DashboardTabsComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    // AngularFontAwesomeModule,
    FontAwesomeModule,
    Ng2CompleterModule,
    FusionChartsModule,
    Ng2LoadingSpinnerModule.forRoot({animationType  : ANIMATION_TYPES.dualCircle, spinnerSize: 'xl'},
    ),
    QuillModule,
    ConfigBuilderModule,
    CompositeWidgetModule,
    ReportModule,
  ],
  exports:[
  	ViewDashboardComponent,
    DashboardTabsComponent,
  	ViDrillFilterComponent,
    QuillModule,
    ConfigBuilderModule,
    CompositeWidgetModule,
    ReportModule,
    // FormsModule,
    // ReactiveFormsModule,
    // AngularFontAwesomeModule,
    // Ng2CompleterModule,
    // Ng2LoadingSpinnerModule,
    FusionChartsModule,
  ]
})
export class DashboardModule { 
  
  async set_fsc_key(){
    if(this.config.raptor_switch){
      let res = await this.config.get_fusionchart_key().toPromise();
      if (res.errCode == 0){
        let fsc_key = atob(res.fsc_key)
        FusionCharts.options.license({
          key: fsc_key,
          creditLabel: false,
        });
      }
    }
  }


  constructor(
    private config:ConfigService
  ){
    this.set_fsc_key();
  }

}


