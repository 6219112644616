import { Injectable } from '@angular/core';


interface query_tabs_data_json {
    'query_name': string,
    'default_name': '',
    'query_string': string,
    'max_count': number,
    'params_list': [],
    'hidden_params_list': [],
    'query_column_list': [],
    'query_column_list_data': [],
    'error_message': string,
    'params_exist': boolean,
    'show_no_of_rows': boolean,
    'queries_operation': number,
    'dt_cols': [],
    'dt_rows': [],
    'filter_dt_rows': [],
    'rec_rows': [],
    'selected_table_recs': [],
    'comparison_recs': [],
    'query_result_table_view': string
}

@Injectable({
    providedIn: 'root'
})


export class TabConfig {

    query_data_json: query_tabs_data_json = {  //make every field and data tab specific
        'query_name': '',
        'default_name': '',
        'query_string': '',
        'max_count': 5,
        'params_list': [],
        'hidden_params_list': [],
        'query_column_list': [],
        'query_column_list_data': [],
        'error_message': '',
        'params_exist': false,
        'show_no_of_rows': false,
        'queries_operation': 0,
        'dt_cols': [],
        'dt_rows': [],
        'filter_dt_rows': [],
        'rec_rows': [],
        'selected_table_recs': [],
        'comparison_recs': [],
        'query_result_table_view': 'normal_view'
    }

    data_json_entity_map = {
        query: JSON.parse(JSON.stringify(this.query_data_json)),
    }

    tabs_entities = { //contains different tabs we need to have in query editor
        query: {
            data_json: JSON.parse(JSON.stringify(this.query_data_json)),
            new_tabs_json: {
                'type': 'dynamic',
                'name': 'Scratch Pad',
                'default_name': 'Scratch Pad',
                'id': 'scratch_pad_1',
                'is_visible': true,
                'icon': '',
                'is_loaded_in_memory': false,
                'data_json': JSON.parse(JSON.stringify(this.data_json_entity_map.query)),
            },
            config: [
                {
                    'type': 'static',
                    'name': 'Query String',
                    'default_name': '',
                    'id': 'querystring',
                    'is_visible': true,
                    'icon': '',
                    'is_loaded_in_memory': false,
                    'data_json': JSON.parse(JSON.stringify(this.data_json_entity_map.query)),
                },
                {
                    'type': 'static',
                    'name': 'Query Comments',
                    'default_name': '',
                    'id': 'querycomments',
                    'is_visible': false,
                    'icon': '',
                    'is_loaded_in_memory': false,
                    'data_json': JSON.parse(JSON.stringify(this.data_json_entity_map.query)),
                },
                {
                    'type': 'static',
                    'name': 'Query History',
                    'default_name': '',
                    'id': 'queryhistory',
                    'is_visible': true,
                    'icon': '',
                    'is_loaded_in_memory': false,
                    'data_json': JSON.parse(JSON.stringify(this.data_json_entity_map.query)),
                },
                {
                    'type': 'dynamic',
                    'name': 'Scratch Pad',
                    'default_name': 'Scratch Pad',
                    'id': 'scratch_pad_1',
                    'is_visible': true,
                    'icon': '',
                    'is_loaded_in_memory': false,
                    'data_json': JSON.parse(JSON.stringify(this.data_json_entity_map.query)),
                }
            ]
        },
    }

    default_tab_config = JSON.parse(JSON.stringify(this.tabs_entities));  //find dwfault_tab_config to again reset to same tabs_config after logout.

    constructor() { }

    reset_tabs_config() { // called whenever login is called and query tabs are reset to their intial satge.
        this.tabs_entities = JSON.parse(JSON.stringify(this.default_tab_config));
        return this.tabs_entities;
    }


}
