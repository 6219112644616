import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges,Output,EventEmitter } from '@angular/core';
import { ConfigService } from '../../config-builder/service/config.service';

declare var $:any;
@Component({
  selector: 'app-view-composite-widget',
  templateUrl: './view-composite-widget.component.html',
  styleUrls: ['./view-composite-widget.component.css']
})
export class ViewCompositeWidgetComponent implements OnInit, OnChanges {
	extra = false;
  	@Input() cw_id;
  	@Input() hidden_filter_object?: any = {};
  	@Input() visible_filter_object?: any = {};
  	@ViewChild('config_builder') cBuilder;
  	@Output() ext_btn_event: EventEmitter<any> = new EventEmitter<any>();
  	loading = true
  	show_view_filter = false
		show_lg_data_recs = true
		show_lg_data_download = true
  	json_data:any = {}
  	view_filter_data = []
	css_data = []
	option_def = {
		'hidden_param_val':[],
		'param_val':[],
		'show_more_limit':5,
	}
	drilldown_order_data = []
	level_data:any = {};
	path = [];
	data_view_config = null;
	constructor(
	    public _configService: ConfigService,
	) { }

	ngOnInit() {
		if (this.cw_id != undefined && this.cw_id != null) {
			this.render();
		}
	}

	render(){
		this.get_lg_composite_widgets()
	}
	ngOnChanges(changes:SimpleChanges) {
		if(this.cw_id != undefined && this.cw_id != null && changes['visible_filter_object'] != undefined && changes['visible_filter_object']['previousValue'] != undefined){
			this.override_filters();
		}
	}

	clear() {
		// this.json_data = {};
		// this.css_data = [];
		this.level_data = {};
		this.path = [];
	}
	async get_data(apply_filter = false){
		this.view_filter_data = JSON.parse(JSON.stringify(this.option_def['param_val']));
		this.loading = true
		let payload = {
			"cw_id": this.cw_id,
			"filter_data": this.option_def['param_val'],
			"hidden_filter_data": this.option_def['hidden_param_val'],
			"drilldown_order_data": []
		}
		let res = await this._configService.get_comp_widget(payload).toPromise();
		if(res.errCode == 0){
			this.clear()
			this.loading = false
			this.json_data = res.msg;
			if(Object.keys(this.json_data).length > 0){
				this.get_level_data(1,this.json_data,0);
			}
		}
	}
	col_detail = []
	row_detail = []
	show_refresh = true
	ext_buttons = []
	async get_lg_composite_widgets(){
		let res = await this._configService.get_widget_by_id({'id':this.cw_id}).toPromise();
        this.data_view_config = res.data.data_view_config? res.data.data_view_config:null;
        if(this.data_view_config == null || (this.data_view_config['show_refresh'] == null || this.data_view_config['show_refresh'] == undefined)){
        	this.show_refresh = true
        }else{
        	this.show_refresh = this.data_view_config['show_refresh']
        }
        if(this.data_view_config == null || (this.data_view_config['show_view_filter'] == null || this.data_view_config['show_view_filter'] == undefined)){
        	this.show_view_filter = false
        }else{
        	this.show_view_filter = this.data_view_config['show_view_filter']
        }
        if(this.data_view_config == null || (this.data_view_config['show_lg_data_recs'] == null || this.data_view_config['show_lg_data_recs'] == undefined)){
        	this.show_lg_data_recs = true
        }else{
        	this.show_lg_data_recs = this.data_view_config['show_lg_data_recs']
        }
        if(this.data_view_config == null || (this.data_view_config['show_lg_data_download'] == null || this.data_view_config['show_lg_data_download'] == undefined)){
        	this.show_lg_data_download = true
        }else{
        	this.show_lg_data_download = this.data_view_config['show_lg_data_download']
        }

        if(this.data_view_config && this.data_view_config.external_buttons){
        	this.ext_buttons = this.data_view_config.external_buttons
        }else{
        	this.ext_buttons = []
        }

		this.css_data = res.data.widget_def;
		this.option_def = res.data.option_def;
		if(res.data.option_def.lg_data_recs_order && res.data.option_def.lg_data_recs_order!= ""){
			this.col_detail = res.data.option_def.lg_data_recs_order.split(";")
		}
		if(!this.option_def.show_more_limit){
			this.option_def.show_more_limit = 5
		}
		let filter_object = {...this.hidden_filter_object, ...this.visible_filter_object};

		let payload = {
			'hidden_filter_data': this.option_def.hidden_param_val,
			'filter_data':this.option_def.param_val,
			'hidden_filter_object': filter_object
		}
		let res2 = await this._configService.process_query_filters(payload).toPromise();
		if(res2.errCode == 0){
			this.option_def.hidden_param_val = res2.msg.hidden_filter_data
			this.option_def.param_val = res2.msg.filter_data
			this.override_filters();
		}
	}
	override_filters(){
		let data = this._configService.process_and_override_filter(this.option_def.hidden_param_val,this.option_def.param_val,this.hidden_filter_object,this.visible_filter_object)
		this.option_def.hidden_param_val = data["hidden_filter_data"]
		this.option_def.param_val = data["filter_data"]
		this.get_data()
	}
	get_level_data(level,json,current_path){
		if(json.level == level){
			this.level_data  = JSON.parse(JSON.stringify(json));
		}else{
			this.get_level_data(level,json.children[this.path[current_path]],current_path+1);
		}
	}

	set_current_path(current_path){
		this.extra = false;
		if(current_path == 'back'){
			this.path.pop()
			let last_level = this.path.length + 1;
			this.get_level_data(last_level,this.json_data,0)
		}else if(current_path == 'home'){
			this.path = [];
			this.level_data = JSON.parse(JSON.stringify(this.json_data));
		}else if(this.path.indexOf(current_path)!= -1){
			this.path = this.path.slice(0,this.path.indexOf(current_path)+1)
			let last_level = this.path.length + 1;
			this.get_level_data(last_level,this.json_data,0)
		}else{
			this.path.push(current_path)
			this.level_data = JSON.parse(JSON.stringify(this.level_data["children"][current_path]))
		}
	}

	toggle_modal(cw_id,op){
		$("#cw_"+cw_id).modal(op);
	}

	show_btn(flag){
		this.extra = flag
		if(flag){
			$("#btn_"+this.cw_id+" .extra").show();
		}else{
			$("#btn_"+this.cw_id+" .extra").hide();
		}
	}
	config_event(event){
		if(event.case == "apply_filter"){
			this.get_data(true);
		}
	}
	open_modal(){
		this.row_detail = this.level_data.widget_map.lg_data_recs
		if(this.col_detail.length == 0){
			this.col_detail = Object.keys(this.row_detail[0])
		}
		$("#widget_data_"+this.cw_id).modal('show')
	}
	// close_modal(){
	// 	$("#widget_data").modal('hide')
	// }

	ext_btn_event_handler(event){
		console.log(event)
		this.ext_btn_event.emit(event);
	}
}
