import { Component, OnInit, Input, OnChanges,OnDestroy, SimpleChanges,Output,EventEmitter } from '@angular/core';
import { ConfigService } from '../../config-builder/service/config.service';
import { SnotifyService } from 'ng-snotify';
import { UtilityService } from '../../config-builder/service/utility.service';
declare var $: any;

@Component({
  selector: 'app-view-dashboard',
  templateUrl: './view-dashboard.component.html',
  styleUrls: ['./view-dashboard.component.css']
})
export class ViewDashboardComponent implements OnInit, OnChanges, OnDestroy {

	// Variables Initialised
		@Input() dashboard_id?: Number = -1;
		@Input() allow_auto_filter?: boolean = true;
		@Output() ext_btn_event: EventEmitter<any> = new EventEmitter<any>();
		dashboard_data: any = {};
		dash_filter = [];
		hidden_dash_filter = [];
		current_id = -1;
		show_all = false;
	    dropdownSettings:any = {}
	    SingledropdownSettings:any = {}
		@Input() hidden_filter_object?:any = {};
		@Input() hidden_filter_input:any='';
		@Input() show_title_desc?:boolean = true;
		@Input() show_row_toggle?:boolean = false;
		visible_filter_object:any = {};
		data_to_populate = {}
	    modules:any = {
			'toolbar':false
	    }
	    show_dash = false;

	constructor(
    	private _configService: ConfigService,
		private snotifyService: SnotifyService,
		private utilityService: UtilityService,

	) {

	}

	ngOnDestroy(){
		localStorage.removeItem('leggero_username')
		//throw new Error('Method not implemented.');
	}
	ngOnInit() {
		this.dropdownSettings = this._configService.search_dropdownSettings
		this.SingledropdownSettings = this._configService.search_SingledropdownSettings
		// // this.hidden_filter_object['From'] = "2020-04-01"
		// this.hidden_filter_object = {
		// 	'user_name':'vusi.maswili@assegai.co.za',
		// 	// 'user_name':'fikile.khumalo@assegai.co.za',
		// }
	}

	ngOnChanges(changes: SimpleChanges) {
		// this.dashboard_data = {
		// 	"dash_params": [],
		// 	"db_file": "",
		// 	"description": "Widget and chart",
		// 	"dtitle": "Widget and chart",
		// 	"id": 59,
		// 	"name": "Widget and chart",
		// 	'row_def':[
		// 		{
		// 			'block_title': 'Block 1',
		// 			'block_description': 'Test description',
		// 			"block_data": [
		// 				[
		// 				  {
		// 					"class": "hlc",
		// 					"col": "6",
		// 					"div_type": "widget",
		// 					"title": "Test",
		// 					"vi_id": 7
		// 				  },
		// 				  {
		// 					"class": "hlc",
		// 					"col": "6",
		// 					"div_type": "chart",
		// 					"title": "test 2",
		// 					"vi_id": 153
		// 				  }
		// 				],
		// 				[
		// 				  {
		// 					"class": "hlc",
		// 					"col": "6",
		// 					"div_type": "widget",
		// 					"title": "Test",
		// 					"vi_id": 7
		// 				  },
		// 				  {
		// 					"class": "hlc",
		// 					"col": "6",
		// 					"div_type": "chart",
		// 					"title": "test 2",
		// 					"vi_id": 153
		// 				  }
		// 				]
		// 			]
		// 		},
		// 		{
		// 			'title': 'Block 2',
		// 			'description': 'Test description 2',
		// 			"data": [
		// 				[
		// 				  {
		// 					"class": "hlc",
		// 					"col": "6",
		// 					"div_type": "widget",
		// 					"title": "Test",
		// 					"vi_id": 7
		// 				  },
		// 				  {
		// 					"class": "hlc",
		// 					"col": "6",
		// 					"div_type": "chart",
		// 					"title": "test 2",
		// 					"vi_id": 153
		// 				  }
		// 				],
		// 				[
		// 				  {
		// 					"class": "hlc",
		// 					"col": "6",
		// 					"div_type": "widget",
		// 					"title": "Test",
		// 					"vi_id": 7
		// 				  },
		// 				  {
		// 					"class": "hlc",
		// 					"col": "6",
		// 					"div_type": "chart",
		// 					"title": "test 2",
		// 					"vi_id": 153
		// 				  }
		// 				]
		// 			]
		// 		},
		// 	]
		//   }

		// return;
		// console.log(this.dashboard_id)
		if(((this.dashboard_id != -1 && this.current_id !== this.dashboard_id) ||
			(changes.hidden_filter_object && !changes.hidden_filter_object.firstChange) && this._configService.raptor_switch)
		){
			this.current_id = parseInt(JSON.parse(JSON.stringify(this.dashboard_id)))
			this.show_dash = false;
			this.load_dashboard();
		}

	}

	async load_dashboard() {	
		var payload={"id":this.dashboard_id,}
											
		const res = await this._configService.get_lg_dashboards(payload).toPromise();
		this.dashboard_data = res.data;
		console.log(this.dashboard_data)
		localStorage.setItem('lg_dashboard_name', this.dashboard_data.dtitle);
		this.dash_filter = this.dashboard_data.dash_params.param_val
		this.hidden_dash_filter = this.dashboard_data.dash_params.hidden_param_val
		this.visible_filter_object = {}
		await this.process_dash_filter();
		if (this.dashboard_data.dash_params.param_val.length > 0) {
			this.show_dash = false;
		} else {
			this.show_dash = true;
		}
		if (this.dashboard_data.dash_view_config && this.dashboard_data.dash_view_config.auto_apply_filters && this.allow_auto_filter) {
			this.validate_filter();
		}
	}

   	clear_filter(){
		for (var i = this.dash_filter.length - 1; i >= 0; i--) {
			if(this.dash_filter[i].nested_form_id){
				this.dash_filter[i].form[0]['paramValue'] = ''
				this.dash_filter[i].form[0]['paramValueList'] = []
				this.onchangeSelect(this.dash_filter[i].nested_form_id,this.dash_filter[i].form[0].column)
			}else{
				this.dash_filter[i]['paramValue'] = ''
				if(this.dash_filter[i]['paramValueList']){
					this.dash_filter[i]['paramValueList'] = []
				}
			}
		}
   	}
   	validate_filter(){
   		let new_case = false;
   		if(!this.show_dash){
   			new_case = true;
   		}else{
   			this.show_dash = false
   		}
   		let temp_visible_filter_object:any = {}
   		for (var i = this.dash_filter.length - 1; i >= 0; i--) {
   			if(this.dash_filter[i].nested_form_id){
   				for (var j = this.dash_filter[i].form.length - 1; j >= 0; j--) {
   					if(this.dash_filter[i].form[j].paramValue != undefined  && ((this.dash_filter[i].form[j].mandatory && this.dash_filter[i].form[j].paramValue != "" ) || !this.dash_filter[i].form[j].mandatory)){
   						if(!this.validate_min_max(this.dash_filter[i].form[j])){
			   				if(!new_case){
			   					this.show_dash = true
			   				}
   							return ;
   						}
   						temp_visible_filter_object[this.dash_filter[i].form[j].paramName] = this.dash_filter[i].form[j].paramValue;
   					}else{
   						this.snotifyService.error("Invalid Nested Filters", this.utilityService.getConfig());
		   				if(!new_case){
		   					this.show_dash = true
		   				}
		   				return ;
   					}
   				}
   			}else if(this.dash_filter[i].paramValue != undefined  && ((this.dash_filter[i].mandatory && this.dash_filter[i].paramValue != "" ) || !this.dash_filter[i].mandatory)){
				if(!this.validate_min_max(this.dash_filter[i])){
	   				if(!new_case){
	   					this.show_dash = true
	   				}
					return ;
				}
   				temp_visible_filter_object[this.dash_filter[i].paramName] = this.dash_filter[i].paramValue;
   			}else{
				this.snotifyService.error("Invalid Filters", this.utilityService.getConfig());

   				if(!new_case){
   					this.show_dash = true
   				}
   				return ;
   			}
   		}
   		this.visible_filter_object = JSON.parse(JSON.stringify(temp_visible_filter_object))
   		this.show_dash = true
   	}
   	validate_min_max(param){
		let minValue = null
		let maxValue = null
		let paramValue = param['paramValue']
		if(param['validation']){
			minValue = param['validation']['minValue']
			maxValue = param['validation']['maxValue']
			if(param['filterType'] == 'Number'){
				paramValue = parseFloat(paramValue)
				if(minValue && minValue != ''){
					minValue = parseFloat(minValue)
				}
				if(maxValue && maxValue != ''){
					maxValue = parseFloat(maxValue)
				}
			}

		}
		if((minValue || minValue == 0)  && paramValue < minValue){
			this.snotifyService.error(param['filterLabel']+" Should be greater than "+param['validation']['minValue'], this.utilityService.getConfig());
			return false
		}else if((maxValue || maxValue == 0)  && paramValue > maxValue)	{
			this.snotifyService.error(param['filterLabel']+" Should be less than "+param['validation']['maxValue'], this.utilityService.getConfig());
			return false
		}

		return true
   	}
	toggle_row(bindex,rindex){
		$("#block_" + bindex + "row_"+rindex).toggle();
		this.dashboard_data['row_def'][bindex]['data'][rindex].toggle_up = !this.dashboard_data['row_def'][bindex]['data'][rindex].toggle_up;
	}

	toggle_block(index){
		$("#block_"+index).toggle();
		this.dashboard_data['row_def'][index].toggle_up = !this.dashboard_data['row_def'][index].toggle_up;
	}

	toggle_all_rows(show){
		this.show_all = !show;
		for(var i=0; i<this.dashboard_data['row_def'].length; i++){
			if(show){
				$("#"+i).show();
			} else {
				$("#"+i).hide();
			}
		}
	}
	onContentChanged(event){

	}
	onItemSelect(item: any,obj) {
		this.dash_filter[obj].paramValue = JSON.parse(JSON.stringify(this.dash_filter[obj].paramValueList.join(';')));
	}
	onSelectAll(items: any,obj) {
		this.dash_filter[obj].paramValueList = items
		this.dash_filter[obj].paramValue = JSON.parse(JSON.stringify(this.dash_filter[obj].paramValueList.join(';')));
	}
	async process_dash_filter(){
		let filter_object = {...this.hidden_filter_object, ...this.visible_filter_object};
		const is_admin_panel=JSON.parse(localStorage.getItem('lg_admin'))
		let user=this.hidden_filter_object['@user_name']

		if (!(user=== null || user === undefined || user=== '')){
		  localStorage.setItem('leggero_username',this.hidden_filter_object["@user_name"])
		}
		
		let payload = {
			"hidden_filter_data": this.hidden_dash_filter,
			"filter_data": this.dash_filter,
			"hidden_filter_object": filter_object,
		};

		if (((localStorage.getItem('leggero_username') != null) || (localStorage.getItem('leggero_username') != undefined))) {

			payload["logdata"] = {
				"logtype": "dashboard_name",
				"logdata": {
					"user_name": localStorage.getItem('leggero_username'),
					"main_itemname": this.dashboard_data.dtitle,
					"main_itemid": this.current_id,
					"is_admin_panel": is_admin_panel == undefined ? 0 : is_admin_panel,
					"system_access_id": localStorage.getItem('system_access_id')
				}
			}
		}

		let res2 = await this._configService.process_query_filters(payload).toPromise();

		if(res2.errCode == 0){
			this.dash_filter = res2.msg.filter_data;
			for (var i = this.dash_filter.length - 1; i >= 0; i--) {
	   			if(this.dash_filter[i].nested_form_id){
   					for (var j = this.dash_filter[i].form.length - 1; j >= 0; j--) {
   						if(this.dash_filter[i].form[j].paramName.indexOf('list') != -1){
							this.dash_filter[i].form[j].paramValueList = JSON.parse(JSON.stringify(this.dash_filter[i].form[j]['filterList']))
							this.dash_filter[i].form[j].paramValue = this.dash_filter[i].form[j].paramValueList.join(";")
   						}
					}
				}else{
					if(this.dash_filter[i].paramName.indexOf('list') != -1){
						this.dash_filter[i].paramValueList = JSON.parse(JSON.stringify(this.dash_filter[i]['filterList']))
						this.dash_filter[i].paramValue = this.dash_filter[i].paramValueList.join(";")

					}
					if(this.dash_filter[i].inputType=='Dropdown'){
						if(this.dash_filter[i].default_value && this.dash_filter[i].filterList.includes(this.dash_filter[i].default_value)){
							this.dash_filter[i].paramValue = this.dash_filter[i].default_value;
						}
					}
				}
			}
			this.hidden_dash_filter = res2.msg.hidden_filter_data;
			for(let i = 0;i < this.hidden_dash_filter.length;i++){
				if(this.hidden_dash_filter[i]['use_default'] && this.hidden_filter_object[this.hidden_dash_filter[i]['paramName']] == undefined){
					if(this.hidden_dash_filter[i]['paramName'].indexOf('list') != -1){
						this.hidden_filter_object[this.hidden_dash_filter[i]['paramName']] = this.hidden_dash_filter[i]['filterList'].join(';')
					}else{
						this.hidden_filter_object[this.hidden_dash_filter[i]['paramName']] = this.hidden_dash_filter[i]['paramValue']
					}
				}
			}
			this.hidden_filter_object = {...this.hidden_filter_object}
		}
		
	}

	getFilterList(data,level,cur,value){
		if(level == cur){
			let filterList = []
			for (var i = 0; i < data.length ; i++) {
				filterList.push(data[i].name)
			}
			return filterList
		}else{
			for (var i = data.length - 1; i >= 0; i--) {
				if(data[i].name == value[cur-1].paramValue){
					return this.getFilterList(data[i].children,level,cur+1,value)
				}
			}
		}
		return []
	}
	getSelectedFormGroup(nested_form_id){
		for (var i = this.dash_filter.length - 1; i >= 0; i--) {
			if(this.dash_filter[i].nested_form_id == nested_form_id){
				return this.dash_filter[i]
			}
		}
		return {}
	}
	onchangeSelect(nested_form_id,current_key){
		let level = parseInt(current_key.split("_").slice(-1)[0])+1
		let form_data = this.getSelectedFormGroup(nested_form_id)
		let filterList = this.getFilterList(form_data.data,level,1,form_data.form)
		for (let i = form_data.form.length - 1; i >= 0; i--) {
			if(parseInt(form_data.form[i].column.split("_").slice(-1)[0]) == level){
				form_data.form[i].filterList = filterList
				form_data.form[i].paramValue = ""
				form_data.form[i].paramValueList = []
			}
			else if (parseInt(form_data.form[i].column.split("_").slice(-1)[0]) > level){
				form_data.form[i].filterList = []
				form_data.form[i].paramValue = ""
				form_data.form[i].paramValueList = []
			}
		}
	}

	onchangeClearSelect(nested_form_id,current_key){
		let level = parseInt(current_key.split("_").slice(-1)[0])+1
		let form_data = this.getSelectedFormGroup(nested_form_id)
		for (let i = form_data.form.length - 1; i >= 0; i--) {
				form_data.form[i].filterList = []
				form_data.form[i].paramValue = ""
				form_data.form[i].paramValueList = []
		}
	}



	populateDefaultFilter(){
		for (let i = this.dash_filter.length - 1; i >= 0; i--) {
			if(this.dash_filter[i].nested_form_id){
				for (let k = 0 ;k<this.dash_filter[i].form.length ; k++) {
					this.dash_filter[i].form[k].paramValue = this.data_to_populate[this.dash_filter[i].form[k].paramName]
					this.onchangeSelect(this.dash_filter[i].nested_form_id,this.dash_filter[i].form[k].column)
				}
			}else{
				this.dash_filter[i].paramValue = this.data_to_populate[this.dash_filter[i].paramName]
			}
		}
	}

	ext_btn_event_handler(event){
		console.log(event)
		this.ext_btn_event.emit(event);
	}
	itemSelectNested(event,nested_form_id,current_key,clear,par){
			par.paramValue = event
			if(clear){
				par.paramValue=''
			}
			par.paramValueList = [par.paramValue]
			this.onchangeSelect(nested_form_id,current_key)
	}
}
