import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CamelCaseWithoutOrderPipe } from './camel-case-without-order.pipe';

import { ConfigBuilderRoutingModule } from './config-builder-routing.module';
import { ConfigFormComponent } from './config-form/config-form.component';
import { JsonText } from './json-text.pipe';
import { TableModalComponent } from './table-modal/table-modal.component';
import { ViewFilterComponent } from './view-filter/view-filter.component'

@NgModule({
  declarations: [ConfigFormComponent,CamelCaseWithoutOrderPipe,JsonText, TableModalComponent, ViewFilterComponent],
  imports: [
    CommonModule,
    ConfigBuilderRoutingModule,
  	FormsModule,
  	ReactiveFormsModule,
  	NgMultiSelectDropDownModule,
    FontAwesomeModule,    	
  ],
  exports:[
  	ConfigFormComponent,
    CamelCaseWithoutOrderPipe,
    JsonText,
    TableModalComponent,
  	FormsModule,
  	ReactiveFormsModule,
  	NgMultiSelectDropDownModule,  
    ViewFilterComponent  
  ]
})
export class ConfigBuilderModule { }
