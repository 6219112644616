import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import {widget_value_configuration} from './widget.model';

declare var $:any;
@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.css']
})
export class WidgetComponent implements OnInit, AfterViewInit {

  @Input() widget_styles?:any = {
    "background-color": '#1ab394',
    "color": 'white',
    "icon_color": 'white'
    // 'height': '45px'
  } 
  @Input() id?: string = 'test';
  @Input() label?: string = 'Average Salary';
  @Input() value?: string = '$19,342';
  @Input() icon?: string = 'money-bill';
  @Input() description?: string = '';
  icon_styles:any = {}; 
  description2?: string = 'sdsa';
  widget_temp_styles :any = {
    "background-color": '#3C6281',
    "color": 'white',
    "icon_color": 'white'
    // 'height': '35px'
  };

  constructor() { }

  ngOnInit() {

    if (this.widget_styles) {
      for (let key in this.widget_temp_styles) {
        if (key in this.widget_styles) {
          // Nothing here
        } else {
          this.widget_styles[key] = this.widget_temp_styles[key];
        }
        if (this.widget_styles[key] == '') {
          this.widget_styles[key] =  this.widget_temp_styles[key];
        }
      }
    } else {
      this.widget_styles['background-color'] = this.widget_temp_styles['background-color'];
      this.widget_styles['color'] = this.widget_temp_styles['color'];
      this.widget_styles['icon_color'] = this.widget_temp_styles['icon_color'];
    }
    this.icon_styles = {
      'stroke': this.widget_styles['icon_color'] ? this.widget_styles['icon_color'] : this.widget_styles['color'],
      'color': this.widget_styles['icon_color'] ? this.widget_styles['icon_color'] : this.widget_styles['color'],
      // 'background': 'white',
      // 'border-radius':'50%',
      // 'padding':'0.5rem 0.6rem',
      // "height": "35px",
      // "width": "35px"
      // 'height': this.widget_styles['height']
    };
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    const widget_styles = {
      "background-color": this.widget_styles['background-color'],
      "color": this.widget_styles['color'],
      "padding": "5px 10px",
      "border-radius": "7px"
    };
    const text_color = {'color': widget_styles["color"]};
    $('.widget-color-'+this.id).css(widget_styles);
    $('.widget-label-'+this.id).css(text_color);
    $('.widget-value-'+this.id).css(text_color);
    $('.widget-description-'+this.id).css(text_color);
    const hr_css = {'border': '1px solid '+ widget_styles["color"]};
    $('.horizontal-line-'+this.id).css(hr_css);
  }

}
